<template>
  <div class="the-solution">
    <v-banner-box :data="swiperData" />
    <v-nav-bar />
    <v-solution-overview :data="solutionOverviewData" />
    <v-functional-framework :imgurl="functionalFramework" />
    <div
      class="
        banner100vw banner100vw-20p30t banner100vw-linear-gradient
        scheme-advantages-bg
      "
      id="advantages"
    >
      <v-scheme-advantages
        :typeslist1="typesList1"
        :typeslist2="typesList2"
      />
    </div>
    <v-customer-stories :case-list="caseList" />
  </div>
</template>

<script>
// NOTE :  解决方案
import vBannerBox from '@/components/banner/bannerBox.vue'
import vNavBar from './components/navBar/index.vue'
import vSolutionOverview from './components/solutionOverview/index.vue'
import vFunctionalFramework from './components/functionalFramework/index.vue'
import vSchemeAdvantages from './components/schemeAdvantages/index.vue'
import vCustomerStories from './components/customerStories/index.vue'
export default {
  components: {
    vBannerBox,
    vNavBar,
    vSolutionOverview,
    vFunctionalFramework,
    vSchemeAdvantages,
    vCustomerStories
  },
  computed: {
    typesList1 () {
      return this.schemeAdvantages.slice(0, 4)
    },
    typesList2 () {
      return this.schemeAdvantages.slice(4, 7)
    }
  },
  data () {
    return {
      swiperData: [],
      solutionOverviewData: {
        subTitleData:
          '文锐公司自2007年成立以来，一直专注于招投标和公共资源交易软件系统研发和技术服务，为众多公共资源交易平台提供了完善的信息化解决方案，产品全面覆盖服务、交易和监管，帮助政府提升公共资源配置的效率和效益，推动公共资源交易从有形市场向电子化平台转变。',
        list: []
      },
      functionalFramework: 'static/img/theSolution/functional-framework.png',
      schemeAdvantages: [
        {
          img: '/static/img/theSolution/b-1.png',
          title: '建立物料库',
          content:
            '支持企业采购中标准化物料采购，建立物料库，可以跟踪物料的价格波动情况'
        },
        {
          img: '/static/img/theSolution/b-2.png',
          title: '特殊授标',
          content:
            '支持企业招采过程中特殊授标方式，单项最低价授标，为企业争取最优采购价；'
        },
        {
          img: '/static/img/theSolution/b-3.png',
          title: '信息流',
          content: '可对接企业内部系统，搭建完整的信息流'
        },
        {
          img: '/static/img/theSolution/b-4.png',
          title: '分量授标',
          content: '支持分量授标，大批量原料分散采购，降低企业生产风险'
        },
        {
          img: '/static/img/theSolution/b-5.png',
          title: '自动评估',
          content:
            '自动评估交易主体信用信息，并生成各交易主体的信用报告，挖掘优秀供应商、淘汰劣质供应商。'
        },
        {
          img: '/static/img/theSolution/b-6.png',
          title: '交易安全',
          content:
            '数字证书技术为核心的交易安全体系：通过CA认证中心的口令、公开密钥和电子签名技术,避免交易主体的不确定性、传输信息的易篡改性以及履行支付中的交易风险，为企业招标采购提供有效的安全技术保障。'
        },
        {
          img: '/static/img/theSolution/b-7.png',
          title: '线上采购',
          content:
            '基于云服务模式的电子采购平台：通过构建了网上虚拟场地环境全程线上采购，使招标工作更加高效、经济、安全、方便、快捷。'
        }
      ],
      caseList: []
    }
  },
  methods: {
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['公共资源交易']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 获取案例 - 公共资源交易
    getCaseList () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.caseTypes['公共资源交易']
      }
      this.$api
        .getCaseInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.caseList = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 获取方案 - 公共资源交易
    getProgrammeList () {
      const params = {
        parentId: this.$siteTypes.programmeTypes['公共资源交易']
      }
      this.$api
        .getProgrammeInfo(params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return console.error(res)
          }
          this.solutionOverviewData.list = res.data
        })
        .catch((err) => {
          console.error(err)
        })
    }
  },
  mounted () {
    this.getSwiperData()
    this.getCaseList()
    this.getProgrammeList()
  }
}
</script>

<style lang="less" scoped>
.the-solution {
  .banner100vw();
  .scheme-advantages-bg {
    &::before {
      background: url('/static/img/theSolution/a-1.png') no-repeat center center /
          100% auto,
        linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    }
  }
}
</style>
